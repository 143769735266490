import React, { useState } from "react";

function useUploadFile() {
  const [images, setImages] = useState<any>();

  const onUploadFile = async (event: React.ChangeEvent<any>) => {
    let imgData: any = [];
    Array.from(event.target.files).map((file: any, index: number) => {
      const reader = new FileReader();
      reader.onload = () => {
        imgData.push(reader.result);
        console.log(event.target.files.length)
        index + 1 === event.target.files.length && setImages(imgData);
      };
      reader.readAsDataURL(file);
    });

    // if (event.target.files && event.target.files.length > 0) {
    //   const reader = new FileReader();
    //   reader.addEventListener("load", () => {
    //     const image = reader.result;
    //     setImages(image);
    //   });
    //   reader.readAsDataURL(event.target.files[0]);
    // }
  };

  return { images, onUploadFile };
}

export default useUploadFile;
