// import Button from "@mui/material/Button";
import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import  ActiveLayer  from "../../../models/constants";
// import { setBaseImgAttributes } from "../../../store/actions";
import {
  checkAspectRatio,
  downloadCroppedImage,
  getActiveBaseImg,
  getActiveLayer,
} from "../../../utils/helper";
import ThumbnailPreview from "../ThumbnailPreview";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./style.scss";
import Button from "@mui/material/Button";

 const ImageLayerPreview:FC<any> = () => {
  const baseImagesState = useSelector((state: any) => state.BaseImages);
  const activeLayerState = useSelector((state: any) => state.ActiveLayer);
  const [activeImg, setActiveImg] = useState<any>({ image: "" });

  useEffect(() => {
    switch (activeLayerState) {
      case ActiveLayer.baseImg:
        setActiveImg(getActiveBaseImg(baseImagesState.baseImages));
        break;
      case ActiveLayer.layers:
        setActiveAttrImage();
        break;
    }
  }, [baseImagesState, activeLayerState]);

  const setActiveAttrImage = () => {
    let activeAttrImages = getActiveLayer(baseImagesState.baseImages).images;
    setActiveImg({image: activeAttrImages.find((item: any) => item.active)?.url});
  };

  return (
    <div className="imgPreview">
      {activeImg && activeImg.image ? (
        <div className="text-center">
          <img className="imgPreview rounded" src={process.env.REACT_APP_API_BASE_URL + activeImg.image} />
          {activeImg.imgConfig && (
             <Button
             variant="contained"
             className="w-[40px] h-[40px] bg-mainBgColor rounded-lg my-4"
             onClick={() => downloadCroppedImage(activeImg.image)}
           >
             <FileDownloadOutlinedIcon />
           </Button>
            // <input
            //   type="file"
            //   onChange={(e: React.ChangeEvent<any>) =>
            //     checkAspectRatio(e.target.files[0], activeImg.imgConfig)
            //       .then((res: any) =>
            //         updateBaseImgAttributes({
            //           image: res,
            //           imgConfig: activeImg.imgConfig,
            //           active: true,
            //         })
            //       )
            //       .catch((e) =>
            //         alert("PLEASE check the aspect ratio of uploaded image")
            //       )
            //   }
            // />
          )}
        </div>
      ) : (
        <p className="text-center mt-4 text-gray-400">No image for preview</p>
      )}
      <br />
      {/* <p className="text-md font-medium mb-4">Preview Selected Images :</p> */}

      {/* <ThumbnailPreview activeLayer={state.activeLayer} attrValConfig = {activeImg?.imgConfig || null} forAttr={layerName} /> */}
    </div>
  );
};

export default ImageLayerPreview;
