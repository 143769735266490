import { BaseImgReqBody } from "./../models/baseImage";
import { BaseImgType } from "./../models/index";
import { appStore } from "../store/index";

// Modify data before calling save baseimage post api
const modifyBaseImgDataForApiCall = (baseImgData: BaseImgType[]) => {
  let modifiedData: BaseImgReqBody[] = [];

  baseImgData = baseImgData.map(({ active, ...rest }: BaseImgType) => rest);
  baseImgData.map((item: any, index: number) => {
    item.backgrounds?.colors &&
      (item.backgrounds.colors = item.backgrounds.colors.map(
        (val: any) => val.color
      ));

    item.backgrounds?.images &&
      (item.backgrounds.images = item.backgrounds.images.map((val: any) => {
        return { url: val.url };
      }));

    item.layers.map((val: any) => {
      return { name: val.name, images: val.values };
    });

    modifiedData[index] = {
      name: item.name,
      image: item.image,
      background: item.backgrounds,
      traits: item.layers,
      collectionId: appStore.getState().Collection._id,
    };
  });

  modifiedData.length && console.log(modifiedData);
  return modifiedData;
};

const modifyDataBefStoringInState = (baseImgsData:any) =>
{
  baseImgsData.map((baseImg:any)=>
  {

    baseImg.background.colors = baseImg.background.colors.map((item:any) => ({color:item,active:true})) 
  })
  return baseImgsData
}

export { modifyBaseImgDataForApiCall,modifyDataBefStoringInState };
