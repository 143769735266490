import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import exportFromJSON from "export-from-json";
import useAxios from "../../../hooks/useAxios";
import { getActiveBaseImg } from "../../../utils/helper";
// const { saveAs } = require("file-saver");
import { dispatchAction as dispatch } from "../../../store/index";
import { downloadNftsAndMetaData } from "../../../store/BaseImages/actions";

function GeneratedNfts() {
  const state = useSelector((state: any) => state.NftData);
  const baseImgState = useSelector((state: any) => state.BaseImages.baseImages);
  const [nftData, setNftData] = useState<any[]>([]);
  const [enableSelectOption, setSelectOption] = useState(false);
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const { makeRequest, response } = useAxios();
  // const totSkeletonLoaders: number = 8;
  const ITEM_HEIGHT = 40;

  const toggleAllNftCheckbox = (event: any) => {
    const checked = event.target.checked;
    setSelectAllCheckbox(checked);
    setSelectOption(checked);
    let nfts = nftData;
    nfts.map((item: any) => (item.checked = checked));
    setNftData(nfts);
  };

  useEffect(() => {
    let nfts: any = [];
    state.nftMetadata.map((item: any) =>
      nfts.push({ url: item.image, checked: false })
    );
    setNftData(nfts);
    setSelectAllCheckbox(false);
    setSelectOption(false);
  }, [state.nftMetadata]);

  const toggleCheckbox = (index: number) => {
    let nfts: any = [...nftData];
    nfts[index].checked = !nfts[index].checked;
    setSelectAllCheckbox(
      nftData.find((item: any) => !item.checked) ? false : true
    );
    setNftData(nfts);
  };

  const downloadNfts = async () => {
    // let data = state.nftMetadata;
    // exportFromJSON({ data, fileName: "NftMetaData", exportType: "json" });
    dispatch(downloadNftsAndMetaData())
    // for (let i = 0; i <= nftData.length; i++) {

    //   nftData[i]?.checked &&
    //     setTimeout(() => {
    //       saveAs(nftData[i].url, `Nft-${i + 1}`);
    //     }, i * 500);
    // }
  };

  const saveNfts = () => {
    const activeBaseImgId = getActiveBaseImg(baseImgState)?._id;
    makeRequest({
      url: `/saveNftMetadata/${activeBaseImgId}`,
      method: "post",
      body: state.nftMetadata,
    });
  };

  useEffect(() => {
    response?.msg && console.log("saved successfully");
  }, [response]);

  return (
    <div className="nftCollection">
      {nftData.length != 0 && (
        <>
          <span className="flex justify-between align-center mt-4 items-center ">
            <p className="text-md font-bold mt-4 mb-4">
              {`Total Generated NFT's (${nftData.length})`}{" "}
            </p>
            <span className="flex justify-end items-center gap-4">
              {enableSelectOption && (
                <DownloadIcon
                  onClick={downloadNfts}
                  sx={{ fontSize: "18px" }}
                  className={`mt-1 cursor-pointer ${
                    !enableSelectOption && "disableDownloadOpt"
                  }`}
                />
              )}

              <SaveIcon
                sx={{ fontSize: "18px" }}
                className={`cursor-pointer `}
                onClick={saveNfts}
              />

              <span className="flex justify-end items-center gap-2">
                <h2>Select All</h2>{" "}
                <input
                  type="checkbox"
                  checked={selectAllCheckbox}
                  onChange={(event: any) => toggleAllNftCheckbox(event)}
                />
              </span>
            </span>
          </span>

          <div className="grid grid-cols-4 gap-4">
            {nftData.map((item: any, index: number) => (
              <div
                className="nft xs:col-span-1 sm:col-span-2 md:col-span-1"
                key={index}
              >
                {enableSelectOption && (
                  <input
                    type="checkbox"
                    onChange={() => toggleCheckbox(index)}
                    checked={item?.checked}
                  />
                )}
                <img src={process.env.REACT_APP_API_BASE_URL + "/"+ item.url} />
              </div>
            ))}
          </div>
        </>
      )}

      {state.isLoading && (
        <div className="loader">
          <CircularProgress size={"50px"} />
        </div>
      )}
    </div>
  );

  // <div className="flex flex-wrap justify-between gap-4 align-center">
  //   {Array(totSkeletonLoaders)
  //     .fill("_")
  //     .map((_: any, index: number) => (
  //      <SkeletonLoader index={index} />

  //     ))}
  // </div>
}

const SkeletonLoader: FC<{ index: number }> = ({ index }) => (
  <Stack spacing={1} key={index}>
    <Skeleton variant="rectangular" animation="wave" width={150} height={150} />
  </Stack>
);

export default GeneratedNfts;
