import ActiveLayer from "../../models/constants";

enum ActiveLayerActionItems{
  setActiveLayerPreview = 'SET_ACTIVE_LAYER_PREVIEW'
}

const updateLayerPreview = (
  val: ActiveLayer.background | ActiveLayer.baseImg | ActiveLayer.layers
) => {
  return { type: ActiveLayerActionItems.setActiveLayerPreview, payload: val };
};

export { updateLayerPreview,ActiveLayerActionItems };