import { BehaviorSubject, Subject } from "rxjs";

const modalOpen  = new BehaviorSubject<boolean>(false);
const actionBtnMsg = new Subject();

const modalOpen$ = modalOpen.asObservable();
const actionBtnMsg$ = actionBtnMsg.asObservable();


const toggleModal = (val:boolean) =>
{
    modalOpen.next(val);
}
const actionBtnSubmitted = (val : 'yes' | 'no') =>
{
    actionBtnMsg.next(val);
    toggleModal(false);
}

export {modalOpen$,actionBtnMsg$,toggleModal,actionBtnSubmitted }