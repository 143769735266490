import Cookies from 'universal-cookie';
const cookies = new Cookies();

// cookies.set('myCat', 'Pacman', { path: '/' });
// console.log(cookies.get('myCat')); // Pacman

function isUserLoggedIn() 
{
   return cookies.get('token');
}

function logout()
{
    cookies.remove('token');
    
}

export {isUserLoggedIn,logout}