import axios, { AxiosInstance } from "axios";
import { useEffect, useState } from "react";
import { setInterceptors } from "../services/interceptor";

interface ApiProps {
  url: string;
  method: "get" | "post";
  body?: any;
  headers?: any;
}

//set base url
const specificAxios: AxiosInstance = setInterceptors(axios);

const useAxios = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);
  const [reqErrors, setErrors] = useState<string>();

  // in future in headers we need to pass token , which will be added for all the api calls

  const  makeRequest = ({ url, method, body = null }: ApiProps)=>
  {
    setLoading(true);

    specificAxios({
      url,
      method,
      data: body
    })
      .then((res:any) => {
        setResponse(res.data);
        setErrors('');
        !res.data && setResponse(res?.response.data)
       
      })
      .catch((err) => {
        setResponse(null)
        setErrors(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return { loading, response, reqErrors, makeRequest};
};

export default useAxios;
