import { useState, useEffect } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
// import GridContainer from "./GridContainer";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { BaseImgType, BgColorType, BgImageType } from "../../models";
import { getActiveBaseImg } from "../../utils/helper";
import { dispatchAction as dispatch } from "../../store/index";
import GeneratedNfts from "../common/GeneratedNfts";
interface TransparentImagesType {
  image: any;
  imageConfig?: any;
}
interface BackgroundData {
  activeBgColor: BgColorType;
  activeBgImg: BgImageType;
}

const Cropper = ({ baseImg }: any) => {
  const initCropConfig: Crop = {
    unit: "px", // Can be 'px' or '%'
    x: 50,
    y: 50,
    width: 0,
    height: 0,
  };

  const appState = useSelector((state: any) => state.BaseImages);
  const [cropConfig, setCropConfig] = useState(initCropConfig);
  const [baseImgRef, setBaseImgRef] = useState();
  const [activeBg, setActiveBg] = useState<BackgroundData>({
    activeBgColor: { color: "", active: false },
    activeBgImg: { url: "", active: false },
  });
  const [activeBaseImg, setActiveBaseImage] = useState<BaseImgType>();
  const [transparentImages, setTransparentImages] = useState<
    TransparentImagesType[]
  >([]);

  useEffect(() => {
    let activeBaseImg = getActiveBaseImg(appState.baseImages);
    if (activeBaseImg.background) {
      // Set background color and Image
      const activeBgColor = activeBaseImg.background.colors.find(
        (item: any) => item.active
      ) || { color: "", active: false };
      const activeBgImg = activeBaseImg.background.images.find(
        (item: any) => item.active
      ) || { url: "", active: false };
      setActiveBg({ activeBgImg, activeBgColor });
    }

    // Set Base image
    setActiveBaseImage(activeBaseImg);

    // Set transparent images
    getAllTransparentLayeredImages();
  }, [appState]);

  useEffect(() => {
    setCropConfig(initCropConfig);
  }, [transparentImages, transparentImages[1]]);

  const getAllTransparentLayeredImages = () => {
    let transparentImages: Array<TransparentImagesType> = [];
    let activeBase = getActiveBaseImg(appState.baseImages) || { layers: [] };
    let layers: any[] = [];
    activeBase?.traits &&
      activeBase?.traits.map((layer: any) => {
        let currLayer = layer.images.find((item: any) => item.active) || {
          url: "",
        };
        layers.push({ image: currLayer.url, active: currLayer.active });
      });
    transparentImages = layers;
    setTransparentImages(transparentImages);
  };

  return (
    <>
      {activeBaseImg?.image && (
        <ReactCrop
          crop={cropConfig}
          src={process.env.REACT_APP_API_BASE_URL+activeBaseImg.image}
          disabled={true}
          onChange={(crop: any) => {
            setCropConfig(crop);
            // setresized((prev) => !prev);
          }}
          onImageLoaded={(imgRef: any) => {
            setBaseImgRef(imgRef);
            setCropConfig(initCropConfig);
          }}
          style={
            activeBg.activeBgImg.url
              ? { backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL + activeBg.activeBgImg.url})` }
              : { background: activeBg.activeBgColor.color }
          }
        >
          {/* <GridContainer cropConfig={cropConfig} setCropConfig={setCropConfig} /> */}

          {transparentImages.length &&
            transparentImages.map(
              (item: any, index: number) =>
                item.image && (
                  <div
                    className="relative bg-transparent"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      pointerEvents: "none",
                      left: "0",
                      top: "0 ",
                    }}
                    key={index}
                  >
                    <img
                      src={process.env.REACT_APP_API_BASE_URL+item.image}
                      style={
                        item.imgConfig && {
                          width: `${item.imgConfig.width}px`,
                          height: `${item.imgConfig.height}px`,
                          position: "absolute",
                          left: `${item.imgConfig.x}px`,
                          top: `${item.imgConfig.y}px`,
                          border: `${
                            index === transparentImages.length - 1
                              ? ""
                              : "1px dashed #ff6f26"
                          }`,
                        }
                      }
                    />
                  </div>
                )
            )}
        </ReactCrop>
      )}
      < GeneratedNfts/>
      {/* <input
        type="file"
        onChange={(e: React.ChangeEvent<any>) =>
          checkAspectRatio(e.target.files[0], cropConfig)
            .then((res) => createAndSetTopLayer(res))
            .catch((e) =>
              alert("PLEASE check the aspect ratio of uploaded image")
            )
        }
      /> */}
    </>
  );
};

export default Cropper;
