import { Provider } from "react-redux";
import "./App.scss";
import SnackbarProvider from "./ContextProviders/SnackbarProvider";
import HomePage from "./pages/HomePage";
import { appStore } from "./store";

function App() {

  return (
    <Provider store={appStore}>
      <SnackbarProvider>
        <HomePage />
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
