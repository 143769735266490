import { FC, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
  modalOpen$,
  actionBtnSubmitted,
} from "../../../services/popupModal.service";
import { takeUntil, Subject } from "rxjs";
import "./style.scss";

const ConfirmationModal: FC<{ msg: string }> = ({ msg }) => {
  const [opened, setOpened] = useState<boolean>(false);
  const destroy$ = new Subject();

  useEffect(() => {
    modalOpen$.pipe(takeUntil(destroy$)).subscribe((res) => setOpened(res));
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, []);

  return (
    <Dialog
      open={opened}
      onClose={() => actionBtnSubmitted("no")}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="modalPopup"
    >
      <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
      <DialogActions>
        <button
          onClick={() => actionBtnSubmitted("no")}
        
        >
          cancel
        </button>
        <button
          onClick={() => actionBtnSubmitted("yes")}
        >
          confirm
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
