import { createContext, useEffect, useState } from "react";
import { Type } from "../enum";


interface State {
  open: boolean;
  message: string;
  type: Type.success | Type.warning | Type.error | Type.info;
}

const initState: State = {
  open: false,
  message: "",
  type: Type.info,
};

export const SnackbarContext = createContext<any>({});

function SnackbarProvider({ children }: any) {
  const [options, setOptions] = useState<State>(initState);
  const value = { options, setOptions };

  useEffect(() => {
    const timer = setTimeout(autoCloseAfterSomeTime, 5000);
    return () => clearTimeout(timer);
  }, [options.message])

  const autoCloseAfterSomeTime = () =>
  setOptions((prev) => {
    return { ...prev, open: false, message: "",type: Type.info };
  });

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarProvider;