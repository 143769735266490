import ActiveLayer from "../../models/constants";
import { ActiveLayerActionItems } from "./actions";

const initState = ActiveLayer.baseImg;


export const ActiveLayerReducer = (state = initState, action: any) => {

    switch(action.type)
    {
        case ActiveLayerActionItems.setActiveLayerPreview:
            return action.payload;    
        default:
            return state;  
    }
}