import { useState, useEffect, forwardRef } from "react";
import { SketchPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { allReducers, appStore } from "../../store";
import { setBgColor } from "../../store/BaseImages/actions";
import { dispatchAction as dispatch} from "../../store/index";

function ColorPicker() {
  const [colors, setColor] = useState<any>({color:''});
  const appState = useSelector((state: any) => state.BaseImages);

  const handleColorChange = (color: any) => {
    // !colorAlreayExists(color.hex) &&
    setColor({
      color: color.hex
    });
  };

  useEffect(() => {
    colors.color && dispatch(setBgColor({color: colors.color,active:true}));
  }, [colors]);

  const colorAlreayExists = (color: string): boolean => appState.bgColors.some((item: any) => item.color === color);

  return (
    <div>
      <SketchPicker
        color={colors?.color}
        onChangeComplete={handleColorChange}
      />
    </div>
  );
}

export default ColorPicker;
