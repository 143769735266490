import { Button } from "@mui/material";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { SnackbarContext } from "../../ContextProviders/SnackbarProvider";
import Cookies from "universal-cookie";
import { Type } from "../../enum";

function SignIn({ onClose }: any) {
  const [singInDetails, setSignInDetails] = useState<{
    username: string;
    password: string;
  }>({ username: "", password: "" });
  const { response, makeRequest } = useAxios();
  const cookies = new Cookies();
  const { setOptions } = useContext(SnackbarContext);

  const usernameInputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (event: any) => {
    setSignInDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const signIn = () => {
    makeRequest({ url: "user/signIn", method: "post", body: singInDetails });
  };

  useEffect(() => {
    usernameInputRef?.current?.focus();
    if (response?.token) {

      cookies.set("token", response.token, {
        path: "/",
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24),
        maxAge: 60 * 60 * 24,
      });
      onClose();
      setOptions({
        open: true,
        message: `Logged in Successfully`,
        type: Type.success,
      })
    }
    // expires and maxage for 1 day
  }, [response]);

  return (
    <span >
      <h3 className="mb-2 title text-center">Sign In</h3>
      <hr></hr>
      <p className="text-md font-semibold mb-2 mt-4">Username :</p>
      <input
        className="field mb-2 w-full"
        type="text"
        name="username"
        ref={usernameInputRef}
        onInput={handleInputChange}
        placeholder={"Enter Username"}
      ></input>
      <p className="text-md font-semibold my-2">Password :</p>
      <input
        className="field mb-4 w-full"
        type="pasword"
        name="password"
        onInput={handleInputChange}
        placeholder={"Enter Password"}
      ></input>

      <div className="text-center mt-4">
        <Button variant="contained" className="mx-auto mt-4" onClick={signIn}>
          Submit
        </Button>
      </div>
    </span>
  );
}

export default SignIn;
