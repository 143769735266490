import { Button } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import useAxios from "../../hooks/useAxios";

function SignUp({ onClose }: any) {
  const [signUpDetails, setSignUpDetails] = useState<{
    username: string;
    password: string;
  }>({ username: "", password: "" });
  const { response, makeRequest } = useAxios();

  const usernameInputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (event: any) => {
    setSignUpDetails((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const signUp = () => {
    makeRequest({ url: "user/signUp", method: "post", body: signUpDetails });
  };

  useEffect(() => {
    usernameInputRef?.current?.focus();
    if (response?.msg === "User created successfully") {
      onClose();
    }
    // expires and maxage for 1 day
  }, [response]);

  return (
    <span>
      <h3 className="mb-2 title text-center">Sign Up</h3>
      <hr></hr>
      <p className="text-md font-semibold mb-2 mt-4">Username :</p>
      <input
        className="field mb-2 w-full"
        type="text"
        name="username"
        ref={usernameInputRef}
        onInput={handleInputChange}
        placeholder={"Enter Username"}
      ></input>
      <p className="text-md font-semibold my-2">Password :</p>
      <input
        className="field mb-4 w-full"
        type="pasword"
        name="password"
        onInput={handleInputChange}
        placeholder={"Enter Password"}
      ></input>

      <div className="text-center mt-4">
        <Button variant="contained" className="mx-auto mt-4" onClick={signUp}>
          Submit
        </Button>
      </div>
    </span>
  );
}

export default SignUp;
