import { Collection } from "../../models";
import { CollectionActions } from "./action";

const initState: Collection = {
  name: "",
  stats: 0,
  description: "",
  image: null,
  _id: "",
};

export const CollectionReducer = (state = initState, action: any) => {
  switch (action.type) {
    case CollectionActions.setActiveCollection:
      return action.payload ;
    default:
      return state;
  }
};