import {
  BgColorType,
  BgImageType,
  Layer,
  LayerValType,
} from "./../../models/index";
import { BaseImgType } from "../../models";
import { updateLayerPreview } from "../ActiveLayer/actions";
import ActiveLayer from "../../models/constants";
import { BaseImageService } from "../../services/baseImage.service";
import { BaseImageActionItems } from "./constants";
import {
  combinations,
  constructSetOfImageUrlsForBaseImg,
  getActiveBaseImg,
  getActiveLayer,
} from "../../utils/helper";
import { setGeneratedNfts } from "../GeneratedNfts/actions";
import mergeImages from "merge-images";
import { modifyDataBefStoringInState } from "../../utils/baseImage";
import { constSetOfMetaDataForBaseImg } from "../../utils/nftMetadata";

// For Background
const setBgColor = (val: BgColorType) => (dispatch: any, getState: any) => {
  dispatch(updateLayerPreview(ActiveLayer.background));
  const baseImgId =
    getActiveBaseImg(getState().BaseImages.baseImages)._id || "";
  BaseImageService.saveBgColor(val.color, baseImgId).then((res) => {
    res.data &&
      dispatch({ type: BaseImageActionItems.setBgColor, payload: val });
  });
};

const deleteBgColor = (val: BgColorType) => (dispatch: any, getState: any) => {
  const baseImgId =
    getActiveBaseImg(getState().BaseImages.baseImages)._id || "";
  BaseImageService.deleteBgColor(val.color, baseImgId).then((res) => {
    res.data &&
      dispatch({ type: BaseImageActionItems.deleteBgColor, payload: val });
  });
};

const setBgActive = (val: BgColorType | BgImageType) => (dispatch: any) => {
  dispatch(updateLayerPreview(ActiveLayer.background));
  dispatch({ type: BaseImageActionItems.setBgActive, payload: val });
};

const setBgImage = (val: BgImageType) => (dispatch: any, getState: any) => {
  dispatch(updateLayerPreview(ActiveLayer.background));
  const baseImgId =
    getActiveBaseImg(getState().BaseImages.baseImages)._id || "";
  BaseImageService.addBgImage(val.url, val.name || "", baseImgId).then(
    (res: any) => {
      debugger;
      console.log(res.data.data);
      res.data.data &&
        dispatch({
          type: BaseImageActionItems.setBgImage,
          payload: res.data.data,
        });
    }
  );
};

const deleteBgImage = (val: BgImageType) => (dispatch: any, getState: any) => {
  const baseImgId =
    getActiveBaseImg(getState().BaseImages.baseImages)._id || "";
  BaseImageService.deleteBgImage(val._id || "", baseImgId).then((res: any) => {
    res.data &&
      dispatch({ type: BaseImageActionItems.deleteBgImage, payload: val });
  });
};

// For Base Images
const addBaseImgs = (baseImgs: BaseImgType[]) => async (dispatch: any) => {
  const result = await BaseImageService.saveBaseImages(baseImgs);
  dispatch({
    type: BaseImageActionItems.addBaseImgs,
    payload: modifyDataBefStoringInState(result.data.data),
  });
};

const setBaseImgs = (baseImgs: BaseImgType[]) => {
  return { type: BaseImageActionItems.setBaseImgs, payload: baseImgs };
};

const deleteBaseImgs =
  (val: { _id: string; active: boolean }) => async (dispatch: any) => {
    await BaseImageService.delBaseImg(val._id);
    dispatch({ type: BaseImageActionItems.deleteBaseImage, payload: val });
  };

const setBaseImgsActive = (val: BaseImgType) => (dispatch: any) => {
  dispatch(updateLayerPreview(ActiveLayer.baseImg));
  dispatch({ type: BaseImageActionItems.setBaseImgActive, payload: val });
};

// For Base Image Layers
const addNewLayer =
  (val: { layer: Layer; baseImgName: string }) =>
  (dispatch: any, getState: any) => {
    dispatch(updateLayerPreview(ActiveLayer.layers));

    const activeBaseImgId = getActiveBaseImg(
      getState().BaseImages.baseImages
    )._id;

    BaseImageService.addTrait(activeBaseImgId || "", {
      name: val.layer.name,
    }).then(
      (res) =>
        res.data &&
        dispatch({
          type: BaseImageActionItems.addLayer,
          payload: { trait: res.data.data, baseImgId: activeBaseImgId },
        })
    );
  };

const setLayerActive = (val: string) => (dispatch: any) => {
  dispatch(updateLayerPreview(ActiveLayer.layers));
  dispatch({ type: BaseImageActionItems.setLayerActive, payload: val });
};

const addNewLayerValue =
  (val: { forLayer: string; layerVal: LayerValType }) =>
  (dispatch: any, getState: any) => {
    const baseImages = getState().BaseImages.baseImages;
    const [baseImgId, traitId] = [
      getActiveBaseImg(baseImages)._id,
      getActiveLayer(baseImages)._id,
    ];
    BaseImageService.addTraitImages(baseImgId || "", {
      url: val.layerVal?.url || "",
      name: val.layerVal.name || "",
      _id: traitId || "",
    }).then(
      (res) =>
        res.data &&
        dispatch({
          type: BaseImageActionItems.addNewLayerVal,
          payload: { traitId, url: res.data.data.url },
        })
    );
  };

const setLayerValActive =
  (val: { forLayer: string; layerVal: LayerValType }) => (dispatch: any) => {
    dispatch(updateLayerPreview(ActiveLayer.layers));
    dispatch({ type: BaseImageActionItems.setLayerValActive, payload: val });
  };

const setLayersOrder = (val: Layer[]) => {
  return { type: BaseImageActionItems.setLayersOrder, payload: val };
};

const deleteLayer = (val: Layer) => (dispatch: any, getState: any) => {
  const [activeBaseImgId, activeTraitId] = [
    getActiveBaseImg(getState().BaseImages.baseImages)._id,
    getActiveLayer(getState().BaseImages.baseImages)._id,
  ];
  BaseImageService.deleteTrait(activeBaseImgId || "", activeTraitId || "").then(
    (res) => {
      res.data &&
        dispatch({ type: BaseImageActionItems.deleteLayer, payload: val });
    }
  );
};

const deleteLayerVal =
  (val: { forLayer: string; layerVal: LayerValType; traitImgId: string }) =>
  (dispatch: any, getState: any) => {
    const baseImages = getState().BaseImages.baseImages;
    const [baseImgId, traitId] = [
      getActiveBaseImg(baseImages)._id,
      getActiveLayer(baseImages)._id,
    ];
    BaseImageService.deleteTraitImg(
      baseImgId || "",
      traitId || "",
      val.traitImgId || ""
    ).then(
      (res) =>
        res.data &&
        dispatch({ type: BaseImageActionItems.deleteLayerVal, payload: val })
    );
  };

// for Edit Names for both base image and trait

const editName = (val: string) => async (dispatch: any, getState: any) => {
  const baseImages = getState().BaseImages.baseImages;
  const activeLayer = getState().ActiveLayer;
  const [baseImgId, traitId] = [
    getActiveBaseImg(baseImages)._id,
    getActiveLayer(baseImages)._id,
  ];

  const result =
    (await activeLayer) === ActiveLayer.layers
      ? BaseImageService.updateTraitName(baseImgId || "", {
          name: val,
          _id: traitId || "",
        })
      : BaseImageService.updateBaseImgName(baseImgId || "", val);

  result
    .then((_) => {
      dispatch({
        type: BaseImageActionItems.editName,
        payload: { name: val, activeLayer },
      });
    })
    .catch((err) => console.log(err));
};

var singleBaseImgNftSet: any = [];

// const generNftsForBaseImg =
//   (baseImgId: string) => async (dispatch: any, getState: any) => {
//     singleBaseImgNftSet = [];
//     const baseImg = getState().BaseImages.baseImages.find(
//       (baseImg: any) => baseImg._id == baseImgId
//     );

//     let arrOfImgProperties = constructSetOfImageUrlsForBaseImg([
//       // getState().BaseImages.baseImages[0],
//       baseImg,
//     ]);

//     // array of baseimage properties
//     // for single base image [ {backgroundImages:[url1, url2], trait-1:{url1,url2}} ]
//     // arrOfImgProperties.map((item) => {
//     //   combinations([...Object.values(item)], getAll);
//     // });
//     // console.log(singleBaseImgNftSet);
//     let allNftsForBaseImg: any = [];

//     //  For all BaseImages i.e for a collection
//     // singleBaseImgNftSet.map((baseImgProperties: any) =>
//     //   mergeImages(baseImgProperties).then((b64: any) => {
//     //     allNftsForBaseImg.push(b64);
//     //   })
//     // );
// constSetOfMetaDataForBaseImg(baseImg).then((res:any) =>
// {
//   console.log(res);
//    dispatch(
//   setGeneratedNfts({
//     for: "baseImg",
//     nftMetadata:res,
//     _id: "",
//     isLoading: false,
//   })
// )});
//     //  For Single BaseImage
//     // mergeImages(singleBaseImgNftSet[0]).then((b64: any) => {
//     //   allNftsForBaseImg.push(b64);
//     // })

//     //  result.map((item:any) => item.then( (b64:any) => allNftsForBaseImg.push(b64)))
//     //  const allNftsForBaseImg =  BaseImageService.generateNfts(singleBaseImgNftSet);

//   };

const generNftsForBaseImg = () => (dispatch: any, getState: any) => {
  BaseImageService.getGeneratedNfts(getState().Collection._id).then(
    (res: any) => {
      console.log(res.data);
      dispatch(
        setGeneratedNfts({
          for: "baseImg",
          nftMetadata: res.data.data,
          _id: "",
          isLoading: false,
        })
      );
    }
  );
};

const downloadNftsAndMetaData = () => (dispatch: any, getState: any) => {
  BaseImageService.downloadNfts(getState().Collection._id).then((res: any) =>
    console.log(res)
  );
};

function getAll(val: any) {
  singleBaseImgNftSet.push(val);
  // for single base image
  // [base image 1, trait 1 image 1, trait 2 image 1]
  // [base image 1, trait 1 image 1, trait 2 image 2]
}

// function downLoadFile(data: any, type: string) {
//   let blob = new Blob([data], { type: type});
//   let url = window.URL.createObjectURL(blob);
//   let pwa = window.open(url);
//   if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
//       alert( 'Please disable your Pop-up blocker and try again.');
//   }
// }

export {
  addBaseImgs,
  setBaseImgs,
  addNewLayer,
  setLayerActive,
  addNewLayerValue,
  setLayerValActive,
  setLayersOrder,
  deleteLayer,
  deleteLayerVal,
  setBgColor,
  deleteBgColor,
  setBgActive,
  deleteBaseImgs,
  setBaseImgsActive,
  setBgImage,
  deleteBgImage,
  editName,
  generNftsForBaseImg,
  downloadNftsAndMetaData,
};
