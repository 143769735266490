import axios, { AxiosInstance } from "axios";
import { BaseImgType } from "../models";
import { BaseImgReqBody } from "../models/baseImage";
import { modifyBaseImgDataForApiCall } from "../utils/baseImage";
import { setInterceptors } from "./interceptor";
const specificAxios: AxiosInstance = setInterceptors(axios);

class BaseImageService {
  // Api's For Base Images
  public static saveBaseImages(data: BaseImgType[]) {
    let modifiedData: BaseImgReqBody[] = modifyBaseImgDataForApiCall(data);
    return specificAxios.post("/baseImages", modifiedData);
  }

  public static getBaseImages(collectionId: string) {
    return specificAxios.get("/baseImages?collectionId=" + collectionId);
  }

  public static delBaseImg(baseImageId: string) {
    return specificAxios.delete("/baseImages/" + baseImageId);
  }

  public static updateBaseImgName(baseImageId: string, name: string) {
    return specificAxios.patch("/baseImages/" + baseImageId, { name });
  }

  // Api's For Base Image Traits
  public static addTrait(baseImageId: string, trait: { name: string }) {
    return specificAxios.post("/baseImages/" + baseImageId + "/traits", {
      name: trait.name,
      images: [{ url: "" }],
    });
  }

  public static updateTraitName(
    baseImageId: string,
    trait: { name: string; _id: string }
  ) {
    return specificAxios.patch(
      "/baseImages/" + baseImageId + "/traits/" + trait._id,
      { name: trait.name }
    );
  }

  public static addTraitImages(
    baseImageId: string,
    trait: { url: string; name: string; _id: string }
  ) {
    return specificAxios.post(
      "/baseImages/" + baseImageId + "/traits/" + trait._id,
      { url: trait.url, name: trait.name }
    );
  }

  public static deleteTraitImg(
    baseImageId: string,
    traitId: string,
    imageId: string
  ) {
    return specificAxios.delete(
      "/baseImages/" + baseImageId + "/traits/" + traitId + "/images/" + imageId
    );
  }

  public static deleteTrait(baseImageId: string, traitId: string) {
    return specificAxios.delete(
      "/baseImages/" + baseImageId + "/traits/" + traitId
    );
  }

  // generate Nfts
  // public static async generateNfts(baseImgProperties: any) {
  //   let allNftsForBaseImg: any = [];
  //   const result = await baseImgProperties.map((item: any) =>
  //     mergeImages(item)
  //   );
  //   result.map((item: any) =>
  //     item.then((b64: any) => allNftsForBaseImg.push(b64))
  //   );
  //   return allNftsForBaseImg;
  // }

  // Apis for baseimg backgrounds

  public static async saveBgColor(color: string, baseImageId: string) {
    return specificAxios.post(
      "baseImages/" + baseImageId + "/background/color",
      null,
      { params: { color } }
    );
  }

  public static deleteBgColor(color: string, baseImgId: string) {
    return specificAxios.delete(
      "baseImages/" + baseImgId + "/background/color",
      { params: { color } }
    );
  }

  public static addBgImage(url: string, name: string, baseImgId: string) {
    return specificAxios.post("baseImages/" + baseImgId + "/background/image", {
      image: { url, name },
    });
  }
  public static deleteBgImage(id: string, baseImgId: string) {
    return specificAxios.delete(
      "baseImages/" + baseImgId + "/background/image",
      { params: { image: id } }
    );
  }

  public static getGeneratedNfts(collectionId:string) 
  {
    return specificAxios.get(`getNftMetaData/${collectionId}`)
  }

  public static downloadNfts(collectionId:string) 
  {
    return specificAxios.get(`download/nfts/${collectionId}`)
  }

}

export { BaseImageService };
