import "./styles.scss";
import { FC, useState, useEffect, useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Box from "@mui/material/Box";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CollectionForm from "./CollectionForm";
import CircularProgress from "@mui/material/CircularProgress";
import useAxios from "../../hooks/useAxios";
import { setActiveCollection } from "../../store/Collection/action";
import { Collection } from "../../models";
import { dispatchAction as dispatch } from "../../store/index";
import { Button, Menu } from "@mui/material";
import { isUserLoggedIn, logout } from "../../services/authService";
import Modal from "@mui/material/Modal";
import SignIn from "../SignIn";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SignUp from "../SignUp";
import { SnackbarContext } from "../../ContextProviders/SnackbarProvider";
import { Type } from "../../enum";

const initCollectionData = [
  {
    name: "Default",
    description: "description",
    stats: 1234,
    image: null,
    _id: "",
  },
];

const CollectionDetails: FC = () => {
  const { response, loading, makeRequest } = useAxios();
  const [showCollectionForm, toggleCollectionForm] = useState<boolean>(false);
  const [collections, setCollections] =
    useState<Collection[]>(initCollectionData);
  const [activeCollection, setSelectedCollection] = useState<Collection>(
    collections[0]
  );

  useEffect(() => {
    getCollections();
  }, []);

  const getCollections = () => {
    makeRequest({ url: "/collections", method: "get", body: null });
  };

  useEffect(() => {
    if (response?.data?.length) {
      setCollections(initCollectionData);
      setCollections((prev) => [...prev, ...response.data]);
    }
  }, [response]);

  const handleChange = (event: SelectChangeEvent) => {
    let selectedCollection =
      collections.find(
        (item: Collection) => item.name === event.target.value
      ) || collections[0];
    setSelectedCollection(selectedCollection);
    dispatch(setActiveCollection(selectedCollection));
  };

  const logo =
    "https://miniextensions.com/wp-content/uploads/sites/5/2019/12/placeholder.com-logo1.png";
  const mintjarLogo =
    "https://mintjar.com/static/media/Logo_mintjar_beta.a5caf16f.svg";

  return (
    <>
      <div className="collectionDetails flex items-center justify-between gap-2 px-4">
        <div className="flex justify-start gap-4 items-center">
          <img src={logo} width={"170px"} height={"20px"} className="my-3" />
          {/* <h3 className=" text-md font-bold ">Collection</h3> */}
          <Box sx={{ minWidth: 220 }}>
            <FormControl fullWidth variant="filled" className="select">
              <InputLabel id="demo-simple-select-label">
                Active Collection
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={activeCollection.name}
                label="Active Collection"
                onChange={handleChange}
              >
                {collections.map((item: any, index: number) => (
                  <MenuItem key={index} value={item.name}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <div
            className="plusIcon"
            onClick={() => toggleCollectionForm((prev) => !prev)}
          >
            {<AddOutlinedIcon />}
          </div>
        </div>
        {showCollectionForm && (
          <CollectionForm
            opened={showCollectionForm}
            onClose={() => {
              toggleCollectionForm(false);
              getCollections();
            }}
          />
        )}
        {activeCollection && (
          <div className="flex justify-start gap-4 items-center mr-4">
            {/* <p className=" text-md font-bold">Collection Details:</p> */}
            {activeCollection?.image?.url && (
              <span
                className="collectionDetailsLabel bg-lightGrey"
                style={{ width: "fit-content", padding: "0" }}
              >
                <img
                  src={
                    process.env.REACT_APP_API_BASE_URL +
                      "/" +
                      activeCollection?.image?.url || ""
                  }
                  style={{ width: "40px", height: "38px" }}
                />
              </span>
            )}

            <span className="collectionDetailsLabel">
              {activeCollection.name}
            </span>
            <span className="collectionDetailsLabel">
              {activeCollection.stats}
            </span>
            <span className="collectionDetailsLabel">
              {activeCollection.description}
            </span>
          </div>
        )}

        {/* <button
          className="w-[140px] h-[43px] rounded-lg pt-2 btn"
          onClick={() => {
            !loggedIn ? handleModalOpen() : setLogout();
          }}
        >
          {loggedIn ? "Logout" : "Login"}
        </button> */}
        <AccountMenu />
      </div>

      {loading && (
        <div className="loader">
          <CircularProgress size={"50px"} />
        </div>
      )}
    </>
  );
};

export default CollectionDetails;

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [modalOptions, setModalOptions] = useState<any>({
    open: false,
    showComp: "login",
  });
  const [loggedIn, setLoggedIn] = useState(isUserLoggedIn());
  const [menuOptions, setMenuOptions] = useState([
    "Login",
    "Register",
    "Logout",
  ]);
  const { setOptions } = useContext(SnackbarContext);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (item: string) => {
    console.log(item)
    switch (item) {
      case "Logout":
        setLogout();
        break;
      case "Login":
        setModalOptions({ open: true, showComp: "login" });
        break;
      case "Register":
        setModalOptions({ open: true, showComp: "register" });
        break;
      default:
        break;
    }

    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setLoggedIn(isUserLoggedIn());
    setModalOptions({ open: false });
  };

  const setLogout = () => {
    logout();
    setLoggedIn(false);
    setOptions({
      open: true,
      message: `Logged Out Successfully`,
      type: Type.success,
    })
  };

  useEffect(() => {
    loggedIn
      ? setMenuOptions(["Logout"])
      : setMenuOptions(["Login", "Register"]);
  }, [modalOptions.open, anchorEl]);

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={menuOpen ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleMenuClick}
      >
        <AccountCircleIcon />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {menuOptions.map((item: any, index: number) => (
          <MenuItem key={index} onClick={() => handleMenuClose(item)}>
            {item}
          </MenuItem>
        ))}
      </Menu>

      <Modal
        open={modalOptions.open}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {modalOptions.showComp === "login" ? (
            <SignIn onClose={handleModalClose} />
          ) : (
            <SignUp onClose={handleModalClose} />
          )}
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
