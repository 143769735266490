import Cookies from "universal-cookie";
import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const cookie = new Cookies();
  config.headers &&
    (config.headers["Authorization"] = `Bearer ${cookie.get("token")}`);
  return config;
};
const onResponse = (response: AxiosResponse): AxiosResponse => {
  // do smoething
  return response;
};

const onResponseError = (error: AxiosError): AxiosError => {
  console.error(`[response error] `, error.response?.data);
  return error;
};

const setInterceptors = (axios: AxiosInstance) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.interceptors.request.use(onRequest);
  axios.interceptors.response.use(onResponse, onResponseError);

  return axios;
};

export { setInterceptors };
