import ActiveLayer from "../../models/constants";
import { getActiveBaseImg } from "../../utils/helper";
import { BaseImageActionItems } from "./constants";
import {
  BaseImgType,
  BgColorType,
  BgImageType,
  Layer,
  LayerValType,
  BaseImgState,
} from "./../../models/index";

const initState: BaseImgState = {
  baseImages: [],
};

export const BaseImagesReducer = (state = initState, action: any) => {
  switch (action.type) {
    // For Background
    case BaseImageActionItems.setBgColor:
    case BaseImageActionItems.setBgImage:
      return addBackground(action.payload, state, action.type);
    case BaseImageActionItems.deleteBgColor:
    case BaseImageActionItems.deleteBgImage:
      return deleteBackground(action.payload, state, action.type);
    case BaseImageActionItems.setBgActive:
      return setBackgroundActive(action.payload, state);

    // For Base Images
    case BaseImageActionItems.setBaseImgs:
      action.payload.map((item: any, index: number) => {
        !index ? (item.active = true) : (item.active = false);
      });
      // action.payload?.length && (action.payload[0].active = true);
      return {
        ...state,
        baseImages: [...action.payload],
      };
    case BaseImageActionItems.addBaseImgs:
      return {
        ...state,
        baseImages: [...state.baseImages, ...action.payload],
      };
    case BaseImageActionItems.deleteBaseImage:
      return deleteBaseImgs(action.payload, state);
    case BaseImageActionItems.setBaseImgActive:
      return setBaseImageActive(action.payload, state);

    // For Layers
    case BaseImageActionItems.addLayer:
      return addNewLayer(action.payload, state);
    case BaseImageActionItems.setLayerActive:
      return setActiveLayer(action.payload, state);
    case BaseImageActionItems.addNewLayerVal:
      return addNewLayerValue(action.payload, state);
    case BaseImageActionItems.setLayersOrder:
      return updateLayersOrder(action.payload, state);
    case BaseImageActionItems.setLayerValActive:
      return setLayerValActive(action.payload, state);
    case BaseImageActionItems.deleteLayer:
      return deleteLayer(action.payload, state);
    case BaseImageActionItems.deleteLayerVal:
      return deleteLayerValue(action.payload, state);

    // For Edit Names
    case BaseImageActionItems.editName:
      return editNames(action.payload, state);
    default:
      return state;
  }
};

// operatios for background
const addBackground = (
  val: any,
  state: BaseImgState,
  forType: BaseImageActionItems.setBgColor | BaseImageActionItems.setBgImage
) => {
  let key: "colors" | "images";
  forType === BaseImageActionItems.setBgColor
    ? (key = "colors")
    : (key = "images");
  let activeBaseImg = getActiveBaseImg(state.baseImages);
  if (activeBaseImg?.background) {
    activeBaseImg.background.colors.map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });
    activeBaseImg.background["images"].map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });
    key === "colors"
      ? (activeBaseImg.background[key] = [
          ...activeBaseImg.background[key],
          { color: val.color, active: true },
        ])
      : (activeBaseImg.background[key] = [
          ...activeBaseImg.background[key],
          { url: val.url, active: true },
        ]);
  }

  state.baseImages.find(
    (item: any) => item.active && (item.background = activeBaseImg.background)
  );

  return { ...state };
};

const deleteBackground = (
  val: any,
  state: BaseImgState,
  forType:
    | BaseImageActionItems.deleteBgColor
    | BaseImageActionItems.deleteBgImage
) => {
  let key: "colors" | "images";
  forType === BaseImageActionItems.deleteBgColor
    ? (key = "colors")
    : (key = "images");
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  if (activeBaseImg && activeBaseImg?.background) {
    let index = activeBaseImg?.background[key].indexOf(val);
    val.active && (activeBaseImg.background[key][index - 1].active = true);
    key === "colors"
      ? (activeBaseImg.background["colors"] = activeBaseImg.background[
          "colors"
        ].filter((item: BgColorType) => item.color != val.color))
      : (activeBaseImg.background["images"] = activeBaseImg.background[
          "images"
        ].filter((item: BgImageType) => item._id != val._id));
  }

  state.baseImages.find((item: BaseImgType) => {
    if (item.active && item.background && activeBaseImg.background) {
      key === "colors"
        ? (item.background.colors = activeBaseImg.background?.colors)
        : (item.background.images = activeBaseImg.background?.images);
    }
  });

  return { ...state };
};

const setBackgroundActive = (
  val: BgColorType | BgImageType,
  state: BaseImgState
) => {
  let key: "colors" | "images";
  "color" in val ? (key = "colors") : (key = "images");
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  if (activeBaseImg.background) {
    activeBaseImg.background["images"].map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });

    activeBaseImg.background["colors"].map((item: any) => {
      item.active = false;
      delete item["showCloseIcon"];
    });

    activeBaseImg.background[key].map((item: any) => {
      "color" in val && item.color === val.color && (item.active = true);
      "url" in val && item.url === val.url && (item.active = true);
    });
  }

  return { ...state };
};

// Operations for base image

const deleteBaseImgs = (
  val: { _id: string; active: boolean },
  state: BaseImgState
) => {
  state.baseImages = state.baseImages.filter(
    (item: any) => item._id != val._id
  );

  return { ...state };
};

const setBaseImageActive = (val: BaseImgType, state: BaseImgState) => {
 
  state["baseImages"].map(
    (item: BaseImgType) => item._id === val._id ? item.active = true : item.active = false
  );
  return { ...state };
};

// operations for Layers

const addNewLayer = (
  val: { trait: string; baseImgId: string },
  state: BaseImgState
) => {
  let { baseImgId, trait } = val;
  state.baseImages.find((item: any) => {
    if (item._id === baseImgId) {
      item.traits?.length &&
        item.traits.map((layer: any) => (layer.active = false));
      item.traits = [...item.traits, trait];
    }
  });
  return { ...state };
};

const setActiveLayer = (val: string, state: BaseImgState) => {
  state.baseImages.find((item: any) => {
    if (item.active) {
      item.traits.map((trait: any) => {
        trait.active = false;
        console.log(trait.name === val);
        trait.name === val && (trait.active = true);
      });
    }
  });

  return { ...state };
};

const addNewLayerValue = (
  val: { traitId: string; url: string },
  state: BaseImgState
) => {
  let { traitId, url } = val;
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  activeBaseImg.traits &&
    activeBaseImg.traits.map((trait: Layer) => {
      trait._id === traitId &&
        trait.images.map((item: any) => (item.active = false)) &&
        trait.images.push({ url });
    });
  return { ...state };
};

const setLayerValActive = (
  val: { forLayer: string; layerVal: LayerValType },
  state: BaseImgState
) => {
  let { forLayer, layerVal } = val;
  const activeBaseImg = getActiveBaseImg(state.baseImages);

  let currLayer =
    activeBaseImg.traits &&
    activeBaseImg.traits.find((item: Layer) => item.name === forLayer);

  currLayer?.images.map((item: LayerValType) => {
    item.active = false;
    item.url === layerVal.url && (item.active = true);
  });

  state.baseImages.find((item: any) => {
    item.active &&
      item.traits.find(
        (layer: any) => layer.name === forLayer && (layer = currLayer)
      );
  });

  return { ...state };
};

const updateLayersOrder = (val: Layer[], state: BaseImgState) => {
  state.baseImages.find((item: any) => {
    item.active && (item.traits = val);
  });
  return { ...state };
};

const deleteLayer = (val: Layer, state: BaseImgState) => {
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  if (activeBaseImg && val && val.name) {
    activeBaseImg.traits = activeBaseImg.traits?.filter(
      (item: Layer) => item.name != val.name
    );
    state.baseImages.find((item: BaseImgType) => {
      item.active && (item.traits = activeBaseImg.traits);
    });
  }
  return { ...state };
};

const deleteLayerValue = (
  val: { forLayer: string; layerVal: LayerValType },
  state: BaseImgState
) => {
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  const { forLayer, layerVal } = val;
  let index;
  if (activeBaseImg) {
    activeBaseImg.traits?.map((item: Layer) => {
      if (item.name === forLayer) {
        index = item.images.indexOf(layerVal);
        layerVal.active && index > 0 && (item.images[index - 1].active = true);
        index != -1 && item.images.splice(index, 1);
        return;
      }
    });
    state.baseImages.find((item: BaseImgType) => {
      item.active && (item.traits = activeBaseImg.traits);
    });
  }
  return { ...state };
};

// Edit names for both baseImage and Layer Names

const editNames = (
  val: { name: string; activeLayer: string },
  state: BaseImgState
) => {
  const activeBaseImg = getActiveBaseImg(state.baseImages);
  const { name, activeLayer } = val;

  activeLayer === ActiveLayer.layers &&
    activeBaseImg.traits?.find(
      (item: Layer) => item.active && (item.name = name)
    );

  state.baseImages.find((item: BaseImgType) => {
    if (item.active) {
      activeLayer === ActiveLayer.layers
        ? (item.traits = activeBaseImg.traits)
        : (item.name = name);
    }
  });

  return { ...state };
};
