import { SetGeneratedNfts } from "./actions";

const initState = {
  for: "baseImg",
  _id: "",
  nfts: [],
  nftMetadata: [],
  isLoading: false,
};

export const SaveGeneratedNftsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case SetGeneratedNfts.saveGenratedNfts:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
