import { CircularProgress, DialogContent } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { FC, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SnackbarContext } from "../../ContextProviders/SnackbarProvider";
import { Type } from "../../enum";
import useAxios from "../../hooks/useAxios";
import "./styles.scss";
interface Props {
  opened: boolean;
  onClose: () => void;
}

const CollectionForm: FC<Props> = ({ opened, onClose }) => {
  const { response, loading, makeRequest, reqErrors } = useAxios();
  const { setOptions } = useContext(SnackbarContext);
  const [open, setOpen] = useState(opened);
  const closeForm = () => {
    setOpen(false);
    onClose();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (formDetails: any) => {
    const formData = new FormData();
    Object.entries(formDetails).map((item: any) => {
      formData.append(item[0], item[0] === "image" ? item[1][0] : item[1]);
    });
    makeRequest({ url: "/collections", method: "post", body: formData });
  };

  useEffect(() => {
    console.log(response,reqErrors)
    if (response?.msg === "Collection is created successfully") {
      closeForm();
      setOptions({
        open: true,
        message: `${response.data.name} is created successfully`,
        type: Type.success,
      });
    } else if (reqErrors) {
      setOptions({
        open: true,
        message: `Collection not created successfully`,
        type: Type.error,
      });
    }
    else if(response?.err === "Token is not Authorized")
    {
      setOptions({
        open: true,
        message: `Please Login or Register`,
        type: Type.error,
      });
    }
    return ()=>
    {
      setOptions({
        open: false,
        message: ``,
        type: Type.info,
      });
    }
  }, [loading]);

  return (
    <>
      <Dialog
        open={open}
        onClose={closeForm}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        className="collectionForm"
      >
        <form onSubmit={handleSubmit(onSubmit)} className="text-center">
          <DialogTitle id="alert-dialog-title">Add New Collection</DialogTitle>
          <DialogContent>
            <input
              placeholder="Enter Collection Name"
              {...register("name", { required: true })}
            />
            {errors.name?.type === "required" && (
              <ErrorMsg msg={"Collection name is required"} />
            )}

            <input
              placeholder="Stats"
              {...register("stats", { required: true })}
            />
            {errors.stats?.type === "required" && (
              <ErrorMsg msg={"Collection stats is required"} />
            )}

            <input
              placeholder="Enter Description "
              {...register("description", { required: true })}
            />
            {errors.description?.type === "required" && (
              <ErrorMsg msg={"Description is required"} />
            )}

            <input
              type="file"
              accept="image/png, image/gif, image/jpeg"
              {...register("image", { required: true })}
            />
            {errors.image?.type === "required" && (
              <ErrorMsg msg={"Collection image is not uploaded "} />
            )}
          </DialogContent>

          <DialogActions>
            <button onClick={closeForm}>cancel</button>
            <button type="submit">Save</button>
          </DialogActions>
        </form>
      </Dialog>
      {loading && (
        <div className="loader">
          <CircularProgress size={"50px"} />
        </div>
      )}
    </>
  );
};

const ErrorMsg: FC<{ msg: string }> = ({ msg }) => {
  return <p className="text-[#e55252] text-left mb-2 text-xs">{msg}</p>;
};

export default CollectionForm;
