import { combineReducers, compose, createStore,applyMiddleware } from "redux";
import { ActiveLayerReducer } from "./ActiveLayer/reducer";
import { BaseImagesReducer } from "./BaseImages/reducer";
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { CollectionReducer } from "./Collection/reducer";
import { SaveGeneratedNftsReducer } from "./GeneratedNfts/reducer";
// redux devtools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// combine reducers
const allReducers = combineReducers({
  ActiveLayer: ActiveLayerReducer,
  BaseImages: BaseImagesReducer,
  Collection:CollectionReducer,
  NftData: SaveGeneratedNftsReducer
});

// middlewares
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const appStore = createStore(allReducers, composeEnhancers(applyMiddleware(thunk)));
const dispatchAction = appStore.dispatch as typeof appStore.dispatch;

export { allReducers, appStore, dispatchAction};
