import React, {
  FC,
  useEffect,
  useState,
  memo,
  useRef,
  useCallback,
  MouseEventHandler,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addNewLayerValue,
  deleteBgColor,
  deleteBgImage,
  deleteLayerVal,
  setBgActive,
  setBgImage,
  setLayerActive,
  setLayerValActive,
} from "../../../store/BaseImages/actions";
import { updateLayerPreview } from "../../../store/ActiveLayer/actions";
import ActiveLayer from "../../../models/constants";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import "./style.scss";
import useUploadFile from "../../../hooks/useUploadFile";
import { getActiveBaseImg } from "../../../utils/helper";
import { dispatchAction as dispatch } from "../../../store";
import TraitForm from "../TraitForm";

interface PreviewProps {
  activeLayer:
    | ActiveLayer.baseImg
    | "bgColors"
    | "bgImages"
    | ActiveLayer.layers;
  forLayer?: string;
}

const ThumbnailPreview: FC<PreviewProps> = ({
  activeLayer,
  forLayer,
}: PreviewProps) => {
  const [currActiveLayerData, setActiveLayerData] = useState<any>([]);
  const state = useSelector((state: any) => state.BaseImages);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { images, onUploadFile } = useUploadFile();
  const [traitFormOpen, handleTraitform] = useState(false);

  useEffect(() => {
    switch (activeLayer) {
      case "bgImages":
        getBgData("images");
        break;
      case "bgColors":
        getBgData("colors");
        break;
      case ActiveLayer.layers:
        getLayersImageData();
        break;
    }
  }, [activeLayer, state, forLayer]);

  useEffect(() => {
    if (images?.length) {
      images.map((item: any) => {
        if (activeLayer === ActiveLayer.layers || ActiveLayer.background) {
          handleTraitform(true);
        } 
      });
    }
  }, [images]);

  const addNewTraitImgVal = (item: string, imgName: string) => {
    dispatch(
      addNewLayerValue({
        forLayer: forLayer || "",
        layerVal: { url: item,name: imgName, active: true },
      })
    );
  };

  const getLayersImageData = () => {
    let layerValues: any = [];
    if (forLayer) {
      let currActiveBaseImg = getActiveBaseImg(state.baseImages);
      let currActiveLayer = currActiveBaseImg?.traits?.find(
        (item: any) => item.name === forLayer
      );
      layerValues = currActiveLayer?.images || [];
    }
    setActiveLayerData(layerValues);
  };

  const getBgData = (forType: "colors" | "images") => {
    const activeBaseImg = getActiveBaseImg(state.baseImages);
    activeBaseImg.background &&
      setActiveLayerData(activeBaseImg.background[forType]);
  };

  const toggleCloseIcon = (event: MouseEvent, val: any) => {
    let data = currActiveLayerData;
    event.type === "mouseleave"
      ? (val.showCloseIcon = false)
      : (val.showCloseIcon = true);
    setActiveLayerData([...data]);
  };

  const removeThumbnail = (event: React.MouseEvent, item: any) => {
    event.stopPropagation();
    switch (activeLayer) {
      case "bgColors":
        dispatch(deleteBgColor(item));
        break;
      case "bgImages":
        dispatch(deleteBgImage(item));
        break;
      case ActiveLayer.layers:
        dispatch(
          deleteLayerVal({
            forLayer: forLayer || "",
            layerVal: item,
            traitImgId: item._id,
          })
        );
        break;
    }
  };

  const setActiveThumbnail = (item: any) => {
    switch (activeLayer) {
      case "bgColors":
      case "bgImages":
        dispatch(setBgActive(item));
        break;

      case ActiveLayer.layers:
        dispatch(
          setLayerValActive({ forLayer: forLayer || "", layerVal: item })
        );
        dispatch(setLayerActive(forLayer || ""));
        break;
    }
  };

  const addThumbnail = () => {
    activeLayer === "bgColors" || activeLayer === "bgImages"
      ? dispatch(updateLayerPreview(ActiveLayer.background))
      : dispatch(setLayerActive(forLayer || ""));

    inputFileRef.current?.click();
  };

  const traitFormClosed = (imgName: string) => {
    handleTraitform(false);
    if(imgName)
    {
      activeLayer === ActiveLayer.layers ? addNewTraitImgVal(images[0], imgName):
      dispatch(setBgImage({ url: images[0],name:imgName, active: true }))
      
    }
  };

  // const uploadNewAtrrVal = (event: any) => {
  //   checkAspectRatio(event?.target?.files[0], attrValConfig)
  //     .then((res) =>
  //     {console.warn({
  //       forAttr: forAttr || '',
  //       attrVal: {
  //         image: res,
  //         imgConfig: attrValConfig,
  //         active: true,
  //       },
  //     });
  //       dispatch(
  //         addNewAttribueValue({
  //           forAttr: forAttr || '',
  //           attrVal: {
  //             image: res,
  //             imgConfig: attrValConfig,
  //             active: true,
  //           },
  //         })
  //       )}
  //     )
  //     .catch((err) => alert(err));
  // };

  return (
    <>
      <div
        className={`previews flex justify-start gap-4 items-center flex-wrap ml-1 ${
          currActiveLayerData.length && "my-4"
        }
        `}
      >
        {currActiveLayerData.map((item: any, index: number) => (
          <div
            className={`rounded cursor-pointer relative ${
              item.active && "active"
            } ${!index && "default"}`}
            key={index}
            onMouseEnter={(event: any) => toggleCloseIcon(event, item)}
            onMouseLeave={(event: any) => toggleCloseIcon(event, item)}
            title={!index ? "Default Null value" : ""}
            style={
              activeLayer === "bgColors"
                ? index > 0
                  ? { background: item.color }
                  : {}
                : index > 0
                ? {
                    backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL+item.url})`,
                    backgroundSize: "contain",
                  }
                : {}
            }
            onClick={() => setActiveThumbnail(item)}
          >
            {item.showCloseIcon && index != 0 && (
              <CancelOutlinedIcon
                className="cancelIcon"
                onClick={(event: React.MouseEvent) =>
                  removeThumbnail(event, item)
                }
              />
            )}
            {/* {item.image && (
              <img className="rounded" src={`${item.image}`} alt="default" />
            )} */}
          </div>
        ))}
        <input
          type="file"
          accept="image/*"
          ref={inputFileRef}
          style={{ display: "none" }}
          onChange={onUploadFile}
        />
        {activeLayer != "bgColors" && activeLayer != "bgImages" && (
          <div className="plusIcon" onClick={addThumbnail}>
            {<AddOutlinedIcon />}
          </div>
        )}
      </div>
      {activeLayer === "bgImages" && (
        <div className="plusIcon mt-4 mx-auto " onClick={addThumbnail}>
          {<AddOutlinedIcon />}
        </div>
      )}
      {traitFormOpen && (
        <TraitForm
          opened={true}
          traitImg={images[0]}
          closeTraitForm={traitFormClosed}
        />
      )}
    </>
  );
};
export default ThumbnailPreview;
