import { useState, useEffect, FC, useRef , useContext} from "react";
import { getActiveLayer, getActiveBaseImg } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import ActiveLayer from "../../models/constants";
import { editName } from "../../store/BaseImages/actions";
import ColorPicker from "./ColorPicker";
import ThumbnailPreview from "../common/ThumbnailPreview";
import "./style.scss";
import ImageLayerPreview from "../common/ImageLayerPreview";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { SnackbarContext } from "../../ContextProviders/SnackbarProvider";
import { dispatchAction as dispatch} from "../../store/index"
;
const LayerPreview: FC = () => {
  const baseImagesState = useSelector((state: any) => state.BaseImages);
  const activeLayerState = useSelector((state: any) => state.ActiveLayer);
  const [activeLayer, setActiveLayer] = useState<ActiveLayer>(
    ActiveLayer.baseImg
  );
  const [inputFieldOptions, setInputFieldOptions] = useState<{
    editable: boolean;
    value: string;
  }>({ editable: false, value: getLayerName() });
  const fieldNameRef = useRef<HTMLInputElement>(null);
  const { setOptions } = useContext(SnackbarContext);


  useEffect(() => {
    setActiveLayer(activeLayerState);
    setInputFieldOptions((prev) => {
      return { ...prev, value: getLayerName() };
    });
  }, [activeLayerState,baseImagesState.baseImages]);

  useEffect(() => {
    setInputFieldOptions((prev) => {
      return { ...prev, value: getLayerName() };
    });

    fieldNameRef &&
      fieldNameRef.current &&
      (inputFieldOptions.editable
        ? fieldNameRef.current.focus()
        : fieldNameRef.current.blur());
  }, [inputFieldOptions.editable]);

  function getLayerName(): string {
    let currActiveLayerName: string = "";
    if (activeLayer === ActiveLayer.layers && baseImagesState?.baseImages) {
      currActiveLayerName = getActiveLayer(baseImagesState.baseImages).name;
    } else if (activeLayer === ActiveLayer.baseImg && baseImagesState?.baseImages) {
      currActiveLayerName = getActiveBaseImg(baseImagesState?.baseImages).name || "";
    }
    return currActiveLayerName;
  }

  function getActivePreviewHeaderTitle(): string {
    let currActiveLayerName: string = "Base Image Workarea";
    if (activeLayer === ActiveLayer.layers) {
      currActiveLayerName = "Traits Workarea";
    } else if (activeLayer === ActiveLayer.background) {
      currActiveLayerName = "Background Workarea";
    }
    return currActiveLayerName;
  }

  function editFieldName(event: any) {
    setInputFieldOptions((prev) => {
      return { ...prev, editable: !prev.editable };
    });
    event.target.value != getLayerName() && setOptions({
      open: true,
      message: `${getLayerName()} has been updated to ${event.target.value}`,
      type: "success",
    });
    dispatch(editName(event.target.value));
  }

  function toggleEdit() {
    setInputFieldOptions((prev) => {
      return { ...prev, editable: !prev.editable };
    });
  }

  function keyPressed(e: any) {
    e.key === "Enter" && editFieldName(e);
    e.key === "Escape" &&
      setInputFieldOptions((prev) => {
        return { ...prev, editable: false, value: getLayerName() };
      });
  }

  return (
    <>
      <div className="layerPreview px-5 pt-[20px]">
        <h2 className="title mb-3">{getActivePreviewHeaderTitle()}</h2>
        {activeLayer != ActiveLayer.background && getLayerName() && (
          <div
            className={`field flex justify-between gap-2 px-2 items-center bg-slate-600 py-2`}
            style={{ justifyContent: "space-between" }}
            onDoubleClick={toggleEdit}
          >
            {!inputFieldOptions.editable ? (
              <p>{getLayerName()}</p>
            ) : (
              <input
                type="text"
                ref={fieldNameRef}
                value={inputFieldOptions.value}
                className="bg-transparent outline-0 border-0 fieldName"
                onBlur={editFieldName}
                onChange={(e: any) =>
                  setInputFieldOptions((prev) => {
                    return { ...prev, value: e.target.value };
                  })
                }
                onKeyDown={keyPressed}
              ></input>
            )}
            {inputFieldOptions.editable ? (
              <KeyboardReturnIcon
                className="cursor-pointer text-slate-400"
                fontSize={"small"}
              />
            ) : (
              <ModeEditOutlineOutlinedIcon
                className="cursor-pointer text-slate-400"
                fontSize={"small"}
                onClick={toggleEdit}
              />
            )}
          </div>
        )}
        {
          {
            [ActiveLayer.baseImg]: (
              <ImageLayerPreview layerName={getLayerName()} />
            ),
            [ActiveLayer.background]: <BgLayerPreview />,
            [ActiveLayer.layers]: (
              <ImageLayerPreview layerName={getLayerName()} />
            ),
          }[activeLayer]
        }
      </div>
    </>
  );
};

const BgLayerPreview = () => {
  return (
    <>
      <ColorPicker />
      <br></br>
      <p className="text-md font-medium mb-4">Selected Colors :</p>
      <div className="mb-4">
        <ThumbnailPreview activeLayer={"bgColors"} />
      </div>
      <p className="orDivider text-sm font-medium relative text-center">OR</p>
      <p className="text-md font-medium mb-4 mt-4">
        Selected Background Images :{" "}
      </p>
      <div className=" mb-4">
        <ThumbnailPreview activeLayer={"bgImages"} />
      </div>
    </>
  );
};

export default LayerPreview;
