import { BaseImageService } from "../../services/baseImage.service";
import { modifyDataBefStoringInState } from "../../utils/baseImage";
import { setBaseImgs } from "../BaseImages/actions";
import { Collection } from "./../../models/index";

export const setActiveCollection = (val: Collection) => (dispatch:any)=>
{
  dispatch({ type: CollectionActions.setActiveCollection, payload: val });
  BaseImageService.getBaseImages(val._id).then(res => dispatch(setBaseImgs(modifyDataBefStoringInState(res.data.data))))
}

export enum CollectionActions {
  setActiveCollection = "SET_ACTIVE_COLLECTION"
}