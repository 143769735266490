import { FC } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

interface SnackBarProps {
  open: boolean;
  handleClose?: () => void;
  message: string;
  type: "success" | "warning" | "error" | "info";
}

const SnackBarComponent: FC<SnackBarProps> = ({
  open,
  handleClose,
  message,
  type,
}) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      key={message}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarComponent;
