import { Layer } from "./../models/index";
import { BaseImgType } from "../models";

function getCroppedImg(sourceImage: any, cropConfig: any, fileName: any) {
  // creating the cropped image from the source image
  const canvas = document.createElement("canvas");
  const scaleX = sourceImage.naturalWidth / sourceImage.width;
  const scaleY = sourceImage.naturalHeight / sourceImage.height;
  canvas.width = cropConfig.width;
  canvas.height = cropConfig.height;
  const ctx = canvas.getContext("2d");
  ctx &&
    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

  return new Promise((resolve, reject) => {
    canvas.toBlob((blob: any) => {
      // returning an error
      if (!blob) {
        reject(new Error("Canvas is empty"));
        return;
      }

      blob.name = fileName;
      // creating a Object URL representing the Blob object given
      const croppedImageUrl = window.URL.createObjectURL(blob);

      resolve(croppedImageUrl);
    }, "image/png");
  });
}

async function downloadCroppedImage(imageSrc: any) {
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "Cropped Image";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function checkAspectRatio(value: any, aspect: any) {
  console.log(value);
  const croppedImgWidth = Math.floor(aspect.width),
    croppedImgHeight = Math.floor(aspect.height);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(value);

    reader.onload = function (value) {
      const img: any = new Image();
      console.log("image is ", value);
      value?.target && (img.src = value.target.result);
      img.onload = function () {
        const aspectRatio = this.width / this.height;
        const croppedImgRatio = croppedImgWidth / croppedImgHeight;
        aspectRatio === croppedImgRatio
          ? resolve(img.src)
          : reject("Please check the dimensions");
      };
    };
  });
}

function getActiveBaseImg(baseImages: BaseImgType[]): BaseImgType {
  return (
    baseImages.find((item: any) => item.active) || {
      name: "",
      image: "",
      traits: [],
    }
  );
}

function getActiveLayer(baseImages: BaseImgType[]): Layer {
  let currActiveBaseImg = getActiveBaseImg(baseImages);
  let currActiveAttr = currActiveBaseImg?.traits?.find(
    (item: any) => item.active
  );
  return currActiveAttr || { name: "", images: [], _id: "" };
}

// function getActiveBgColor(state: RootState) {
//   return state.background.colors.find((item: any) => item.active)?.color || "";
// }
// function getActiveBgImage(state: RootState) {
//   return (
//     state.background.images.find((item: any) => item.active) || { image: "" }
//   );
// }

// for generating Combinations

function combinations(
  choices: Array<any>,
  callback: (val: any) => void,
  prefix?: any
) {
  if (!choices.length) {
    return callback(prefix);
  }
  for (var c = 0; c < choices[0].length; c++) {
    combinations(
      choices.slice(1),
      callback,
      (prefix || []).concat(choices[0][c])
    );
  }
}

// var color = ["red", "blue", "green", "yellow"];
// var size = ["small", "medium", "large"];
// var weight = ["heavy", "light"];

// combinations([color, size, weight], console.log.bind(console));

const constructSetOfImageUrlsForBaseImg = (baseImgs: any) => {
  const setOfData: any[] = [];
  var set: any;

  // output : backgroundImages : [url1, url2]
  // trait - 1: [url1, url2]
  // trait - 2: [url1, url2] 

  baseImgs.map((baseImg: any) => {
    set = {};
 
    set["backgroundImages"] = baseImg.background.colors.map(
      (item: any) => item.color
    );
    set["backgroundImages"] = [...getUrls(set["backgroundImages"])];

    baseImg.background.images.map((item: any) => {
      item.url && set["backgroundImages"].push(item.url);
    });
    set["baseImage"]?.length
      ? set["baseImage"].push(baseImg.image)
      : (set["baseImage"] = [baseImg.image]);

    baseImg.traits.map((trait: any, index: any) => {
      let traitImgValues = trait.images.map((item: any) => {
        return item.url != "" && item.url;
      });

      traitImgValues = traitImgValues.filter((item: any) => item);
      set[`trait-${index}`]?.length
        ? set[`trait-${index}`].push(...traitImgValues)
        : (set[`trait-${index}`] = traitImgValues);
    });
    setOfData.push(set);
  });

  //set next layers for traits
  console.log(setOfData);
  return setOfData;
};

var urls: any = [];
var canvas = document.createElement("canvas");
canvas.width = 3000;
canvas.height = 3000;

function getUrls(colors: any) {
  colors = colors.filter((item: any) => item);
  urls = [];
  colors.map((item: any) => {
    var ctx: any = canvas.getContext("2d");
    ctx.fillStyle = item;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    urls.push(canvas.toDataURL());
  });

  return urls;
}

export {
  getCroppedImg,
  downloadCroppedImage,
  checkAspectRatio,
  getActiveBaseImg,
  getActiveLayer,
  constructSetOfImageUrlsForBaseImg,
  combinations,
  getUrls
  // getActiveBgColor,
  // getActiveBgImage,
};
