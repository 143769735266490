import React, { FC, useState, useEffect, useRef, useContext } from "react";
import "./style.scss";
import { BaseImgType, Layer } from "../../models/index";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import ThumbnailPreview from "../common/ThumbnailPreview";
import ActiveLayer from "../../models/constants";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import useUploadFile from "../../hooks/useUploadFile";
import {
  addNewLayer,
  deleteBaseImgs,
  deleteLayer,
  editName,
  addBaseImgs,
  setBaseImgsActive,
  setLayerActive,
  setLayersOrder,
  generNftsForBaseImg,
} from "../../store/BaseImages/actions";
import { updateLayerPreview } from "../../store/ActiveLayer/actions";
import {
  actionBtnMsg$,
  modalOpen$,
  toggleModal,
} from "../../services/popupModal.service";
import { Subject, takeUntil } from "rxjs";
import { SnackbarContext } from "../../ContextProviders/SnackbarProvider";
import { dispatchAction as dispatch } from "../../store/index";
import { getActiveBaseImg } from "../../utils/helper";
import { setGeneratedNfts } from "../../store/GeneratedNfts/actions";

const Layers: FC = () => {
  const [baseImageData, setbaseImageData] = useState<any[]>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);

  const baseImagesState = useSelector((state: any) => state.BaseImages);
  const { images, onUploadFile } = useUploadFile();

  useEffect(() => {
    setbaseImageData(baseImagesState.baseImages);
  }, [baseImagesState]);

  useEffect(() => {
    if (images?.length) {
      let reqBody: any = [];
      images.map((item: any, index: number) => {
        reqBody.push({
          name: `Base Image ${getTitleIdForBaseImg(
            baseImagesState.baseImages || [],index
          )}`,
          image: item,
          active: false,
          layers: [],
          backgrounds: {
            colors: [{ color: "", active: true }],
            images: [{ url: "", active: true }],
          },
        });
      });

      dispatch(addBaseImgs(reqBody));
    }
  }, [images]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      baseImageData,
      result.source.index,
      result.destination.index
    );
    baseImageData.map((item: BaseImgType) => (item.active = false));
    setbaseImageData(items);
  };

  const accordiontoggle = (
    event: React.SyntheticEvent,
    expanded: boolean,
    name: string,
    image: any,
    _id: string
  ) => {
    expanded && dispatch(setBaseImgsActive({ name, image,_id }));
  };

  const generateNfts = () => {
    const activeBaseImgId = getActiveBaseImg(baseImagesState.baseImages)._id;
    dispatch(generNftsForBaseImg());
  };

  return (
    <div className="layersContainer px-5 pt-[20px]">
      <div className="flex justify-between mb-4 items-center">
        <h2 className="title"> Base Images </h2>
        <Button
          variant="contained"
          className=" h-[40px] bg-mainBgColor text-center"
          onClick={() => inputFileRef.current?.click()}
        >
          <span className="text-lg">+</span>
        </Button>
      </div>
      {baseImageData?.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided: any, snapshot: any) => (
              <div
                className="layers mb-4 text-center"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {baseImageData?.map((item: BaseImgType, index: number) => (
                  <Draggable
                    key={index}
                    draggableId={`item-${index}`}
                    index={index}
                    isDragDisabled={true}
                  >
                    {(provided: any) => (
                      <div
                        className="mb-2"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <BaseImgAcc
                          {...item}
                          index={index}
                          accordiontoggle={accordiontoggle}
                          key={index}
                        />
                      </div>
                    )}
                  </Draggable>
                  // <BaseImgAcc {...item}  key={index}/>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <p className="text-center my-4 text-gray-400">No image for preview</p>
      )}
      <input
        type="file"
        accept="image/*"
        ref={inputFileRef}
        multiple
        style={{ display: "none" }}
        onChange={onUploadFile}
      />
      {baseImageData.length ? (
        <Button
          variant="contained"
          className="w-full h-[40px] bg-mainBgColor"
          onClick={() => {
            dispatch(setGeneratedNfts({ isLoading: true, nftMetadata:[] }));
            generateNfts();
          }}
        >
          Generate
        </Button>
      ) : null}
    </div>
  );
};

const BaseImgAcc: FC<
  BaseImgType & { index: number } & {
    accordiontoggle: (
      event: React.SyntheticEvent,
      expanded: boolean,
      name: string,
      image: any,
      _id: string
    ) => void;
  }
> = ({
  _id,
  name,
  image,
  background,
  traits,
  index,
  active,
  accordiontoggle,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [baseImglayers, setBaseImgLayers] = useState<Layer[]>(traits || []);
  const baseImagesState = useSelector((state: any) => state.BaseImages);
  const activeLayerState = useSelector((state: any) => state.ActiveLayer);
  const [layerToBeDeleted, setDeletedLayer] = useState<any>("");
  const [baseImgtoBeDeleted, setBaseImgToBeDeleted] = useState<any>("");
  const [modalData, setModalData] = useState<{
    actionBtnMsg: string;
    modalopened: boolean;
  }>({ modalopened: false, actionBtnMsg: "" });
  const destroy$ = new Subject();
  const { setOptions } = useContext(SnackbarContext);
  const [inputFieldOptions, setInputFieldOptions] = useState<{
    initVal: string;
    updatedVal: string;
  }>({ initVal: "", updatedVal: "" });
  const inputRefForTraitTitle = useRef<HTMLInputElement>(null);

  useEffect(() => {
    modalOpen$.pipe(takeUntil(destroy$)).subscribe((res: any) =>
      setModalData((prev: any) => {
        return { ...prev, modalopened: res };
      })
    );
    actionBtnMsg$.pipe(takeUntil(destroy$)).subscribe((res: any) =>
      setModalData((prev: any) => {
        return { ...prev, actionBtnMsg: res };
      })
    );
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, []);

  const addAttribute = () => {
    dispatch(
      addNewLayer({
        layer: {
          name: inputRef.current?.value || "",
          active: true,
          images: [{ url: "", active: true }],
        },
        baseImgName: name || "",
      })
    );
    inputRef && inputRef.current && (inputRef.current.value = "");
  };

  const getLayersForBaseImg = (): Layer[] => {
    return baseImagesState.baseImages.find(
      (item: BaseImgType) => item.name === name
    ).traits;
  };

  const removeLayer = (item: Layer) => {
    setDeletedLayer(item);
    toggleModal(true);
  };

  const removeBaseImg = (name: string, activeOrNot: boolean) => {
    setBaseImgToBeDeleted({ name, active: activeOrNot });
    toggleModal(true);
  };

  useEffect(() => {
    if (!modalData.modalopened && modalData.actionBtnMsg === "yes") {
      layerToBeDeleted && dispatch(deleteLayer(layerToBeDeleted));
      baseImgtoBeDeleted &&
        dispatch(deleteBaseImgs({ _id: _id || "", active: active || false }));
      setBaseImgToBeDeleted("");
      setDeletedLayer("");
    }
  }, [modalData.modalopened]);

  useEffect(() => {
    setBaseImgLayers(getLayersForBaseImg());
  }, [traits]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(
      baseImglayers,
      result.source.index,
      result.destination.index
    );
    setBaseImgLayers(items);
    dispatch(setLayersOrder(items));
  };

  // useEffect(() => {
  //  if(inputRefForTraitTitle && inputRefForTraitTitle.current)
  //  {
  //   inputFieldOptions.editable ?
  //   inputRefForTraitTitle.current.focus() :
  //   inputRefForTraitTitle.current.blur();
  //  }
  // }, [inputFieldOptions.editable])

  const enableEdit = (item: Layer) => {
    disabelEditModeAllLayers();
    item.editable = true;
    let layers = baseImglayers;
    layers.map(
      (layer: Layer) => layer.name === item.name && (layer.editable = true)
    );
    setBaseImgLayers(layers);
    setInputFieldOptions((prev) => {
      return { ...prev, initVal: item.name, updatedVal: item.name };
    });
  };

  const enableInputFocus = (val: boolean) => {
    if (inputRefForTraitTitle && inputRefForTraitTitle.current) {
      val
        ? inputRefForTraitTitle.current.focus()
        : inputRefForTraitTitle.current.blur();
    }
  };

  const disabelEditModeAllLayers = () => {
    let layers = baseImglayers;
    layers.map((layer: Layer) => (layer.editable = false));
    setBaseImgLayers(layers);
  };

  const editFieldName = () => {
    const { initVal, updatedVal } = inputFieldOptions;
    enableInputFocus(false);
    disabelEditModeAllLayers();
    if (initVal != updatedVal) {
      setOptions({
        open: true,
        message: `${initVal} has been updated to ${updatedVal}`,
        type: "success",
      });
      setInputFieldOptions((prev) => {
        return { ...prev, value: updatedVal };
      });
      dispatch(editName(updatedVal));
    }
  };

  const keyPressed = (e: any) => {
    e.key === "Enter" && editFieldName();
    e.key === "Escape" &&
      setInputFieldOptions((prev) => {
        return { ...prev, editable: false };
      });
  };

  return (
    <>
      <Accordion
        className="mb-2 bg-white border-2 border-slate-100 rounded-md shadow-none"
        onChange={(event: React.SyntheticEvent, expanded: boolean) =>
          accordiontoggle(event, expanded, name || "", image,_id || '')
        }
        expanded={active}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div
            className="flex gap-4 justify-start items-center w-full"
            onClick={() => dispatch(updateLayerPreview(ActiveLayer.baseImg))}
          >
            <img src={process.env.REACT_APP_API_BASE_URL + image} className="baseImgthumbnail" />
            <Typography className="text-md font-medium">{name}</Typography>
          </div>
          <DeleteOutlineOutlinedIcon
            className="deleteIcon"
            onClick={() => removeBaseImg(name || "", active || false)}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className="px-2">
            <p className="mb-3 layerTitle">BACKGROUNDS </p>
            <div
              className={`field cursor-pointer text-left   ${
                activeLayerState === ActiveLayer.background && "activeLayer"
              }`}
              onClick={() =>
                dispatch(updateLayerPreview(ActiveLayer.background))
              }
            >
              Colors and Images :
            </div>
            <ThumbnailPreview activeLayer={"bgColors"} />

            {background?.images.length && background?.colors.length ? (
              <hr />
            ) : null}

            <ThumbnailPreview activeLayer={"bgImages"} />

            <p className="pt-4 my-4 layerTitle">TRAITS</p>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {baseImglayers?.map((item: Layer, index: number) => (
                      <Draggable
                        key={index}
                        draggableId={`item-${index}`}
                        index={index}
                      >
                        {(provided: any) => (
                          <div
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="mb-4"
                          >
                            <span>
                              <div
                                className={`field cursor-pointer text-left relative ${
                                  item.active &&
                                  activeLayerState === ActiveLayer.layers &&
                                  "activeLayer"
                                } mb-2 ${item.editable && "p-1"}`}
                                onClick={() =>
                                  dispatch(setLayerActive(item.name))
                                }
                                onDoubleClick={() => enableEdit(item)}
                              >
                                {item.editable ? (
                                  <input
                                    style={{ width: "100%" }}
                                    ref={inputRefForTraitTitle}
                                    value={inputFieldOptions?.updatedVal || ''}
                                    className="bg-transparent outline-0 border-0 fieldName"
                                    onBlur={editFieldName}
                                    onKeyDown={keyPressed}
                                    autoFocus={true}
                                    onChange={(e: any) =>
                                      setInputFieldOptions((prev) => {
                                        return {
                                          ...prev,
                                          updatedVal: e.target.value,
                                        };
                                      })
                                    }
                                  />
                                ) : (
                                  <span>{item.name}</span>
                                )}

                                <CancelOutlinedIcon
                                  className="cancelIcon"
                                  onClick={() => removeLayer(item)}
                                />
                              </div>

                              <ThumbnailPreview
                                activeLayer={ActiveLayer.layers}
                                forLayer={item.name}
                              />
                            </span>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div
              className="field flex justify-between gap-2 px-2 py-2 items-center bg-slate-600"
              style={{ justifyContent: "space-between" }}
            >
              <input
                ref={inputRef}
                type="text"
                placeholder="New Layer"
                onKeyDown={(e: any) =>
                  e.target.value && e.key === "Enter" && addAttribute()
                }
              />
              <p className="addAttr" onClick={addAttribute}>
                +
              </p>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Layers;

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result: Layer[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getTitleIdForBaseImg = (baseImages: any[],index:number) => {
  let title, newBaseImgId;
  if (baseImages?.length) {
    title = baseImages.slice(-1)[0].name;
    newBaseImgId = parseInt(title.split(" ")[2]) + 1;
  } else newBaseImgId = 1;

  return newBaseImgId + index;
};
