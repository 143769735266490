enum BaseImageActionItems {
    addBaseImgs = "ADD_BASE_IMAGES",
    setBaseImgs = "SET_BASE_IMAGES",
    deleteBaseImage = "DELETE_BASE_IMAGE",
    setBaseImgActive = "SET_BASE_IMG_ACTIVE",
    addNewAttrValForActive = "ADD_NEW_ATTR_VAL_FOR_ACTIVE",
  
    addLayer = "ADD_NEW_LAYER",
    setLayerActive = "SET_LAYER_ACTIVE",
    addNewLayerVal = "ADD_NEW_LAYER_VAL",
    setLayerValActive = "SET_LAYER_VAL_ACTIVE",
    setLayersOrder = "SET_LAYERS_ORDER",
    deleteLayer = "DELETE_LAYER",
    deleteLayerVal = "DELETE_LAYER_VAL",
  
    setBgColor = "SET_BG_COLOR",
    deleteBgColor = "DELETE_BG_COLOR",
    setBgImage = "SET_BG_IMAGE",
    deleteBgImage = "DELTE_BG_IMAGE",
    setBgActive = "SET_BG_ACTIVE",
    setAttrValActive = "SET_ATTR_VAL_ACTIVE",
    deleteAttrVal = "DELETE_ATTR_VAL",
  
    editName = "EDIT_NAMES",
  }
  export {BaseImageActionItems};