import Workspace from "../components/Workspace/index";
import LayerPreview from "../components/LayerPreview/index";
import Layers from "../components/Layers/index";
import ConfirmationModal from "../components/common/ConfirmationPopup";
import CollectionDetails from "../components/Collection";
import SnackBarComponent from "../components/common/SnackBar";
import { SnackbarContext } from "../ContextProviders/SnackbarProvider";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

function HomePage() {
  const {options} = useContext(SnackbarContext);

  return (
    <>
      <CollectionDetails />
      <div className="flex mainContainer">
        <Layers />
        <LayerPreview />
        <Workspace />
        <ConfirmationModal msg={"Are you sure you want to delete?"} />
      </div>
      <SnackBarComponent {...options}/>
    
     
    </>
  );
}

export default HomePage;
