import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import React, { useState, FC, createRef, useEffect, useContext } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarContext } from "../../../ContextProviders/SnackbarProvider";
import { Type } from "../../../enum";

const TraitForm: FC<{
  opened: boolean;
  traitImg: any;
  closeTraitForm: (imgName: string) => void;
}> = ({ opened, traitImg, closeTraitForm }) => {
  const {setOptions} = useContext(SnackbarContext);
  const handleClose = () => {
    inputRef?.current?.value ? closeTraitForm(inputRef?.current?.value ) : 
    setOptions({open: true,message:"Enter the Image Name",type: Type.warning})
  };
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    inputRef?.current && inputRef?.current?.focus();
  }, []);

  const handleInputChange = (event: any) => {
    event.key === "Enter" && handleClose();
  };

  return (
    <>
      <Modal
        open={opened}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.boxStyles}>
          <CloseIcon onClick={handleClose} sx={styles.closeIconStyles} />
          <img src={traitImg} className="border-2 border-gray mb-4 mt-3" />
          <p className="text-md font-semibold mb-3">
            Enter image name for this trait :
          </p>
          <input
            className="field w-full"
            type="text"
            ref={inputRef}
            onKeyDown={handleInputChange}
          />
        </Box>
      </Modal>
    </>
  );
};

const styles = {
  boxStyles: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  closeIconStyles: {
    position: "absolute",
    right: 8,
    top: "12px",
    cursor: "pointer",
  },
};

export default TraitForm;
